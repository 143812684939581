import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Snackbar from '@material-ui/core/Snackbar';
import {getB2ChildrenComponents} from 'util/B2Utils.js';
import {Modal} from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    //GET_NAV_FILTERS,
    CLOSE_B2_MESSAGE,
    //DISCARD_CONFIRMATION_ACCEPTED, DISCARD_CONFIRMATION_CANCELLED, SAVE_CONFIRMATION_ACCEPTED, SAVE_CONFIRMATION_CANCELLED
    } from 'constants/B2ActionTypes';
import {getDatasource, getBean} from 'util/B2Utils';

class B2App extends React.Component {
    handleConfirmationMsgClicked = (optionIndex) => {
        const b2Event = {
            ...this.props.actionPendingConfirmation
        }

        const options = this.props.b2Message.confirmationType.split("_");

        b2Event.eventDetails.confirmation = options[optionIndex];

        this.props.processB2Event(b2Event);
    }

//    handleRequestCancelClicked = () => {
//        const b2Event = {
//            ...this.props.actionPendingConfirmation
//        }
//
//        const options = this.props.b2Message.confirmationType.split("_");
//
//        b2Event.eventDetails.confirmation = options[0];
//
//        this.props.processB2Event(b2Event);
//    }

    handleRequestClose = () => {
        const b2Event = {
            type: CLOSE_B2_MESSAGE,
            eventDetails: {}
        }
        this.props.processB2Event(b2Event);
    }

//    discardConfirmationAccepted = () => {
//        const b2Event = {
//            type: DISCARD_CONFIRMATION_ACCEPTED,
//            eventDetails: {}
//        }
//        this.props.processB2Event(b2Event);
//    }
//
//    discardConfirmationCancelled = () => {
//        const b2Event = {
//            type: DISCARD_CONFIRMATION_CANCELLED,
//            eventDetails: {}
//        }
//        this.props.processB2Event(b2Event);
//    }
//
//    saveConfirmationAccepted = () => {
//        const b2Event = {
//            type: SAVE_CONFIRMATION_ACCEPTED,
//            eventDetails: {}
//        }
//        this.props.processB2Event(b2Event);
//    }
//
//    saveConfirmationCancelled = () => {
//        const b2Event = {
//            type: SAVE_CONFIRMATION_CANCELLED,
//            eventDetails: {}
//        }
//        this.props.processB2Event(b2Event);
//    }

    render() {
        return (
            <div className="app-wrapper" key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}>
                <div className="animated slideInUpTiny animation-duration-3">
                    {
                        (this.props.appLoader)?
                            <div className="loader-view"
                               style={{height: this.props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)'}}>
                               <CircularProgress/>
                            </div>:
                            (
                                (this.props.app.attributes.type.value === 'Advanced' )?
                                    <div className="app-module">
                                        {getB2ChildrenComponents(this.props, this.props.uiDefinition.appBody, getDatasource(this.props.uiDefinition.appBody, this.props, null), getBean(this.props.uiDefinition.appBody, this.props, null))}
                                    </div>:
                                    getB2ChildrenComponents(this.props, this.props.uiDefinition.appBody, getDatasource(this.props.uiDefinition.appBody, this.props, null), getBean(this.props.uiDefinition.appBody, this.props, null))
                            )
                    }
                    <Snackbar
                      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                      open={this.props.b2Message && this.props.b2Message.display && this.props.b2Message.display.toLowerCase()==='snackbar'}
                      style={{textAlign: 'center'}}
                      autoHideDuration={3000}
                      onClose={this.handleRequestClose}
                      ContentProps={{
                        'aria-describedby': 'message-id',
                      }}
                      message={<span id="message-id">{(this.props.b2Message)?this.props.b2Message.message:""}</span>}
                    />
                </div>
                <Modal centered className="modal-box" style={{width: '200px', height: '200px'}} isOpen={typeof(this.props.longOperation)!=='undefined' && (this.props.longOperation === "true" || this.props.longOperation)} key={this.props.uiDefinition.id+'_longoperatio'} id={this.props.uiDefinition.id+'longoperation'}>
                    <div key={'b2_appcontainer_longoperation'} id={'b2_appcontainer_longoperation'}

                        className="loader-view"
                        style={{
                            width: '200px', height: '200px', paddingLeft: "10px", paddingRight: "10px"
                        }}
                    >
                        <label>{(typeof(this.props.longOperationText)!=='undefined' && this.props.longOperationText !== "")?this.props.longOperationText:"Please wait ..."}</label>
                        <br/><br/>
                        <CircularProgress />
                    </div>
               </Modal>

                {this.props.b2Message &&
                    !this.props.b2Message.confirmationType && this.props.b2Message.display && this.props.b2Message.display.toLowerCase()==='popup' && <SweetAlert show={typeof(this.props.b2Message) !== 'undefined'}
                    type={this.props.b2Message.type.toLowerCase()}
                    confirmBtnText={this.props.b2Message.btnText}
                    confirmBtnBsStyle={this.props.b2Message.confirmBtnBsStyle}
                    title={this.props.b2Message.title}
                    onConfirm={this.handleRequestClose}
                >
                    <span style={{wordBreak: "break-word"}}>{this.props.b2Message.message}</span>
                </SweetAlert>}

                {this.props.b2Message &&
                    this.props.b2Message.confirmationType && this.props.b2Message.display && this.props.b2Message.display.toLowerCase()==='popup' && <SweetAlert show={typeof(this.props.b2Message) !== 'undefined'}
                    type={this.props.b2Message.type.toLowerCase()}
                    showCancel
                    confirmBtnText={this.props.b2Message.confirmBtnText}
                    confirmBtnBsStyle={this.props.b2Message.confirmBtnBsStyle}
                    cancelBtnText={this.props.b2Message.cancelBtnText}
                    cancelBtnBsStyle={this.props.b2Message.cancelBtnBsStyle}
                    title={this.props.b2Message.title}
                    onCancel={() => {this.handleConfirmationMsgClicked(0)}}
                    onConfirm={() => {this.handleConfirmationMsgClicked(1)}}
                >
                    <span style={{wordBreak: "break-word"}}>{this.props.b2Message.message}</span>
                </SweetAlert>}
                {/*
               <SweetAlert show={this.props.discardPendingAction!=null}
                    warning
                    showCancel
                    confirmBtnText='Discard and continue'
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title=''
                    onConfirm={this.discardConfirmationAccepted}
                    onCancel={this.discardConfirmationCancelled}
               >
                    There are changes that are not yet saved, do you want to discard those changes and continue?
                </SweetAlert>

                <SweetAlert show={this.props.saveRecordPendingAction!=null}
                    warning
                    showCancel
                    confirmBtnText='Save and continue'
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="default"
                    title=''
                    onConfirm={this.saveConfirmationAccepted}
                    onCancel={this.saveConfirmationCancelled}
                >
                    This action requires that the record is saved first, record will be saved automatically
                </SweetAlert>*/}
            </div>
        );
    }
}

export default B2App;