import React from 'react';
import Button from '@material-ui/core/Button';
import B2Icons from 'components/B2/Utils/icons/B2Icons';

class B2Button extends React.Component {
    sendEvent = () => {
        const b2Event = {
            type: this.props.uiDefinition.event,
            eventDetails: {
                beanId: this.props.bean.id,
                datasourceId: this.props.datasource.id
            }
        }

        this.props.processB2Event(b2Event);
    }

    render() {
        let style = null;
        if (this.props.style){
            style = this.props.style;
        } else if (this.props.uiDefinition.style){
            style = this.props.uiDefinition.style;
        }else{
            style = { "margin" : "12px 7px"}
        }

        let TargetIconTag = null;
        if (this.props.uiDefinition.icon){
            TargetIconTag = B2Icons[this.props.uiDefinition.icon];
        }

        if (TargetIconTag == null){
            return (
                <Button variant="contained" color="primary" className="jr-btn text-white"
                        onClick={() => {
                            this.sendEvent();
                        }} style={style}>
                        {this.props.uiDefinition.label}
                </Button>
            );
        }else{
            return (
                <Button variant="contained" color="primary" startIcon={<TargetIconTag />} className="jr-btn text-white"
                        onClick={() => {
                            this.sendEvent();
                        }} style={style}>
                        {this.props.uiDefinition.label}
                </Button>
            );
        }
    }
}

export default B2Button;