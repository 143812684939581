import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import options from 'app/routes/b2/app/data/selectRecordsOptions';  // TODO: this should be loaded from server, or at least saga, another option is to define it static for this component
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';

import {
    ON_TABLE_CHANGE_PAGE,
    ON_TABLE_CHANGE_RECORDS_PER_PAGE,
    ON_RECORDS_CHECKED, ON_RECORDS_UNCHECKED,
    ON_DEL_UNDEL_TABLE_CHECKED_RECORDS,
    ON_TOGGLE_TABLE_FILTER
} from 'constants/B2ActionTypes';

class B2TableHeader extends React.Component {
    handleChangePage = (event, page) => {
        const b2Event = {
            type: ON_TABLE_CHANGE_PAGE,
            eventDetails: {
                beanId: this.props.bean.id,
                page: page
            }
        }
        this.props.processB2Event(b2Event);
    }

    toggleTableFilter = () => {
        if (this.props.datasource.entityList){
            const b2Event = {
                type: ON_TOGGLE_TABLE_FILTER
            }
            this.props.parentCallback(b2Event);
        }
    }

    areAllRecordsInPageChecked = () =>{
        if (!this.props.datasource.entityList || this.props.datasource.entityList.totalCount === 0 || typeof(this.props.bean.checkedRecords) === 'undefined' || this.props.bean.checkedRecords===null){
            return false;
        }

        let startIndex = this.props.bean.currentPage * this.props.bean.recordsPerPage;
        let endIndex = (startIndex+this.props.bean.recordsPerPage)>this.props.datasource.entityList.totalCount?this.props.datasource.entityList.totalCount:(startIndex+this.props.bean.recordsPerPage);

        for(let i = startIndex ; i < endIndex; i++) {
            if (!this.props.bean.checkedRecords.includes(this.props.datasource.entityList.data[i].id)) {
                return false;
            }
        }

        return true;
    }

    handleChangeRowsPerPage = event => {
        const b2Event = {
            type: ON_TABLE_CHANGE_RECORDS_PER_PAGE,
            eventDetails: {
                beanId: this.props.bean.id,
                recordsPerPage: event.target.value
            }
        }
        this.props.processB2Event(b2Event);
    }

    checkAllRecordsInPage = () => {
        if (!this.props.datasource.entityList || this.props.datasource.entityList.totalCount === 0){
            return;
        }

        let recordsToCheck = [];
        let startIndex = this.props.bean.currentPage * this.props.bean.recordsPerPage;
        let endIndex = (startIndex+this.props.bean.recordsPerPage)>this.props.datasource.entityList.totalCount?this.props.datasource.entityList.totalCount:(startIndex+this.props.bean.recordsPerPage);

        for(let i = startIndex ; i < endIndex; i++) {
            if (typeof(this.props.bean.checkedRecords) === 'undefined' || this.props.bean.checkedRecords === null || !this.props.bean.checkedRecords.includes(this.props.datasource.entityList.data[i].id)) {
                recordsToCheck.push(this.props.datasource.entityList.data[i].id);
            }
        }

        if (recordsToCheck.length >0){
            const b2Event = {
                type: ON_RECORDS_CHECKED,
                eventDetails: {
                    beanId: this.props.bean.id,
                    recordsIds: recordsToCheck
                }
            }
            this.props.processB2Event(b2Event);
        }
    };

    uncheckAllRecordsInPage = () => {
        if (!this.props.datasource.entityList || this.props.datasource.entityList.totalCount === 0 || typeof(this.props.bean.checkedRecords) === 'undefined' || this.props.bean.checkedRecords === null){
            return;
        }

        let recordsToUncheck = [];
        let startIndex = this.props.bean.currentPage * this.props.bean.recordsPerPage;
        let endIndex = (startIndex+this.props.bean.recordsPerPage)>this.props.datasource.entityList.totalCount?this.props.datasource.entityList.totalCount:(startIndex+this.props.bean.recordsPerPage);

        for(let i = startIndex ; i < endIndex; i++) {
            if (this.props.bean.checkedRecords.includes(this.props.datasource.entityList.data[i].id)) {
                recordsToUncheck.push(this.props.datasource.entityList.data[i].id);
            }
        }

        if (recordsToUncheck.length >0){
            const b2Event = {
                type: ON_RECORDS_UNCHECKED,
                eventDetails: {
                    beanId: this.props.bean.id,
                    recordsIds: recordsToUncheck
                }
            }
            this.props.processB2Event(b2Event);
        }
    };

    uncheckAllRecords = () => {

        let startIndex = this.props.bean.currentPage * this.props.bean.recordsPerPage;
        let endIndex = (startIndex+this.props.bean.recordsPerPage)>this.props.datasource.entityList.totalCount?this.props.datasource.entityList.totalCount:(startIndex+this.props.bean.recordsPerPage);

        const recordsAllCheckedAlready = this.areAllRecordsInPageChecked();

        this.uncheckAllRecordsInPage();
    }

    onOptionMenuItemSelect = (option) => {
        switch (option.title) {
            case 'All in current list page':
                this.handleRequestClose();
                this.checkAllRecordsInPage();
                break;
            case 'None in current list page':
                this.handleRequestClose();
                this.uncheckAllRecordsInPage();
                break;
            case 'None':
                this.handleRequestClose();
                this.uncheckAllRecords();
                break;
            case 'Bookmarked':
                this.handleRequestClose();
                this.checkAllBookmarkedRecordsInPage();
                break;
            case 'Unbookmarked':
                this.handleRequestClose();
                this.checkAllBookmarkedRecordsInPage();
                break;
            default:
                this.handleRequestClose();
                this.checkAllRecordsInPage();
        }
    };

    onOptionMenuSelect = event => {
        this.setState({
            anchorEl: event.currentTarget,
            optionMenuOpen: true
        })
    };

    onDelUndelRecord = () => {
        if (typeof(this.props.bean.checkedRecords) !== 'undefined' && this.props.bean.checkedRecords.length > 0){
            const b2Event = {
                type: ON_DEL_UNDEL_TABLE_CHECKED_RECORDS,
                eventDetails: {
                    beanId: this.props.bean.id
                }
            }
            this.props.processB2Event(b2Event);
        }
    };

    getRecordActions = () => {
        if (!this.props.uiDefinition.disableDelete || this.props.uiDefinition.disableDelete!=="true"){
            return (typeof(this.props.bean.checkedRecords) !== 'undefined' && this.props.bean.checkedRecords.length > 0) &&
                    <IconButton onClick={this.onDelUndelRecord} className="icon-btn">
                        <i className="zmdi zmdi-delete"/>
                    </IconButton>
        }
        return;
    };

    handleRequestClose = () => {
        this.setState({
            anchorEl: null,
            optionMenuOpen: false
        });
    };

    isFilterable(){
        if (this.props.parentUiDefinition){
            let tableDef = this.props.parentUiDefinition;
            if (tableDef.B2TableBody){
                let tableBody = tableDef.B2TableBody;
                if (tableBody.B2TableCol){
                    if (Array.isArray(tableBody.B2TableCol)){
                        for(let i = 0 ; i < tableBody.B2TableCol.length; i++) {
                            if (tableBody.B2TableCol[i].filterable == "true"){
                                return true;
                            }
                        }
                    }else{
                        return tableBody.B2TableCol.filterable == "true";
                    }
                }
            }
        }

        return false;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            anchorEl: null,
            optionMenuOpen: false
        };
    }

    render() {
        return (
            <React.Fragment key={this.props.uiDefinition.id}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" padding="none">
                            <Toolbar className="table-header" style={{"paddingLeft": "12px"}}>

                                <Table style={{tableLayout: 'auto', border: '0px'}}>
                                    <TableHead>
                                        <TableRow>
                                        <th style={{"width":"20px"}}>
                                            <div className="actions d-flex">
                                                {
                                                    (this.isFilterable()) &&
                                                    <Tooltip title={((this.props.toggleTableFilter)?"Hide ":"Show ") + "Table Filter"}>
                                                        <IconButton aria-label="Filter list" onClick={this.toggleTableFilter}>
                                                            <FilterListIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                }

                                            {/*
                                                <div className="d-flex">
                                                    <div className="d-flex align-items-center" onClick={this.onOptionMenuSelect.bind(this)}>
                                                        <span className="px-2"> {this.props.optionName}</span>
                                                        <IconButton className="icon-btn-sm">
                                                            <i className="zmdi zmdi-caret-down"/>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            */}
                                                {this.getRecordActions()}
                                            </div>
                                                <Menu id="option-menu"
                                                    anchorEl={this.state.anchorEl}
                                                    open={(typeof(this.state.optionMenuOpen)==='undefined' || true)?false:this.state.optionMenuOpen}
                                                    onClose={this.handleRequestClose}

                                                    MenuListProps={
                                                        {
                                                            style: {
                                                                width: 180,
                                                            },
                                                        }
                                                    }>
                                                    {
                                                        options.map(option =>
                                                            <MenuItem key={option.title}
                                                                    onClick={this.onOptionMenuItemSelect.bind(this, option)}>
                                                            {option.title}
                                                            </MenuItem>,
                                                        )
                                                    }
                                                </Menu>
                                            </th>
                                            <TablePagination
                                                style={{border: '0px'}}
                                                count={this.props.datasource.entityList?this.props.datasource.entityList.totalCount:0}
                                                rowsPerPage={this.props.beans[this.props.parentUiDefinition.id].recordsPerPage}
                                                page={this.props.beans[this.props.parentUiDefinition.id].currentPage}
                                                onPageChange={this.handleChangePage}
                                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                            />
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </Toolbar>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </React.Fragment>
        );
    };
}

export default B2TableHeader;