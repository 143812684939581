//// B2 App Module const
export const PROCESS_B2_EVENT = 'processB2Event';
export const LOAD_B2_APP = "loadB2App";
export const ON_APP_TOGGLE_DRAWER = "toggleDrawer";
export const CLOSE_B2_MESSAGE = "closeB2Message";
export const ON_TABLE_CHANGE_PAGE = 'onTableChangePage';
export const ON_TABLE_CHANGE_RECORDS_PER_PAGE = 'onTableChangeRecordsPerPage';
export const ON_CHANGE_APP_PAGE = 'changeAppPage';
export const SHOW_RECORD_DETAILS = 'showRecordDetails';
export const ON_RECORD_SELECTED = 'selectRecord';
export const ON_LOOKUP_RECORD_SELECTED = "lookupRecordSelect";
export const ON_RECORDS_CHECKED = "checkRecords";
export const ON_RECORDS_UNCHECKED = "uncheckRecords";
export const ON_ALL_RECORDS_UNCHECKED = "uncheckAllRecords";
export const ON_TOGGLE_BOOKMARK_RECORD = "toggleBookmarkRecord";
export const ON_RECORD_FIELD_VALUE_CHANGED = "onRecordFieldValueChanged"; // used for event invoked as value of field is being changed (for every key strock)
export const ON_RECORD_ATTRIBUTE_UPDATED = "onRecordAttributeUpdated"; // used to send attribute change event to the server
export const SAVE_RECORD = "save";
export const ON_INSERT_MAIN_RECORD = "insert";
export const ON_DELETE_MAIN_RECORD = "delete";
export const EXECUTE_QUERY = "executeQuery";
export const CLOSE_DIALOG = "closeDialog";
export const ON_SELECT_OPENED = "onSelectOpened";
export const ON_LOOKUP_OPENED = "onLookupOpened";
export const ON_TAB_CHANGED = "onTabChanged";
export const ON_DEL_UNDEL_TABLE_CHECKED_RECORDS = "onDelUnDelTableCheckedRecords";
export const ON_APP_SEARCH_FIELD_VALUE_CHANGED = "onAppSearchFieldValueChanged";
export const ON_APP_SEARCH_PERFORMED = "onAppSearchPerformed";
export const ON_TOGGLE_TABLE_FILTER = "onToggleTableFilter";
export const ON_QBE_SEARCH_PERFORMED = "onQbeSearchPerformed";
export const ON_LIST_SORT = "onListSort";
export const TOGGLE_TABLE_DETAILS = "toggleTableDetails";

export const PROCESS_B2_SECURITY_EVENT = 'processB2SecurityEvent';
export const SIGNUP_USER = 'signup';
export const SIGNIN_USER = 'signin';
export const SIGNOUT_USER = 'signout';
export const SET_INIT_URL = 'setInitURL';
export const RESET_PASSWORD_REQUEST = "resetPasswordRequest";
export const RESET_PASSWORD = "resetPassword";
export const ACTIVATE_ACCOUNT = "activate";

export const PROCESS_B2_LOCATION_EVENT = '@@router/LOCATION_CHANGE'; // automatically generated by the system for Link and NavLink components

export const TOKEN_KEY = 'userToken';

export const PROCESS_B2_DOWNLOAD_ATTACHMENT_EVENT = 'processB2DownloadAttachmentEvent';

export const DOWNLOAD_ATTACHMENT = "downloadAttachment";

export const LONG_OPERATION = "longOperation";

export const RELOAD_DATASOURCE = "reloadDatasource"

export const SHOW_ERROR_MESSAGE = "showErrorMessage";