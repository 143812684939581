import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
  let variant = "determinate";
  if (!props.value || props.value == 0){
    variant = "indeterminate";
  }

  let value = props.value;
  if (value == 0){
    value = "";
  }else{
    value = Math.round(props.value) + '%';
  }
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant={variant} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary' }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function CircularWithValueLabel(props) {
//  const [progress, setProgress] = React.useState(0);
//
//  React.useEffect(() => {
//    const timer = setInterval(() => {
//      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
//    }, 800);
//    return () => {
//      clearInterval(timer);
//    };
//  }, []);

  return <CircularProgressWithLabel value={props.value} />;
}