import Refresh from '@mui/icons-material/Refresh';
import PlaylistAdd from '@mui/icons-material/PlaylistAdd';
import Key from '@mui/icons-material/Key';
import GroupAdd from '@mui/icons-material/GroupAdd';
import Groups from '@mui/icons-material/Groups';
import People from '@mui/icons-material/People';

export default{
    "Refresh": Refresh,
    "PlaylistAdd": PlaylistAdd,
    "Key": Key,
    "GroupAdd": GroupAdd,
    "Groups": Groups,
    "People": People
}

